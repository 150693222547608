<template>
  <div>
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :span="12">
        <div class="center">
          <h1>{{ $tc('paymentAction') }}.</h1>
          <small>{{ $tc('paymentHeader') }}.</small>
        </div>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :xs="24">
        <el-form
          ref="formPayment"
          v-loading="form.loading"
          :model="form.model.redirectRequest.buyer"
          :rules="form.rules"
        >
          <el-row
            v-if="form.model.voucher && ['Paid'].includes(form.model.voucher.voucherStatus)"
            :gutter="20"
          >
            <el-col>
              <div class="card-container">
                <div class="card-column col-select current current-selected">
                  <div class="card">
                    <div class="content">
                      <div class="purchase">
                        <div class="purchase-image">
                          <img
                            :alt="$tc('payed')"
                            class="image"
                            src="/img/transactions/approved.svg"
                            style="max-height: 250px"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="secured">
                        <small class="text-primary">
                          <h3>{{ $tc('voucherAlreadyPayed') }}</h3>
                        </small>
                        <div>
                          <small>{{ $tc('voucherAlreadyPayedMessage') }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="secured">
                        <small class="text-dark">
                          <h3>{{ $tc('paymentForPlan') }}</h3>
                        </small>
                        <div>
                          <h1>
                            {{
                              form.model.voucher.voucherAmountTotal | formatToCurrencyWithIsoCode
                            }}
                          </h1>
                          <small
                            >/{{
                              $tc(
                                $options.filters.toCamelCase(
                                  form.model.company.companyServiceFrequencyBilling
                                )
                              )
                            }}.</small
                          >
                        </div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="footer">
                        <span class="sub">
                          <small>
                            <em>
                              <address>
                                <a href="mailto:#">facturacion@aliaddo.com</a>
                              </address>
                              <address>
                                <el-button
                                  size="mini"
                                  type="text"
                                  @click="dialog.askedQuestionsElectronicPayments.visible = true"
                                  >{{ $tc('requentlyAskedQuestions') }}</el-button
                                >
                              </address>
                            </em>
                          </small>
                        </span>
                        <div class="" style="font-weight: bold">
                          <el-row :gutter="20">
                            <el-col :xs="24" :md="8">
                              <img
                                src="/img/place_to_pay/MASTERCARD.png"
                                alt="Mastercard"
                                height="20"
                              />
                              <img src="/img/place_to_pay/PSE.png" alt="Pse" height="20" />
                              <img src="/img/place_to_pay/VISA.png" alt="Visa" height="20" />
                              <img
                                src="/img/place_to_pay/AMEX.png"
                                alt="American Express"
                                height="20"
                              />
                            </el-col>
                            <el-col :xs="24" :md="8">
                              <a
                                href="https://aliaddo.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src="@/assets/img/svg/logo-aliaddo.svg" height="30" />
                              </a>
                            </el-col>
                            <el-col :xs="24" :md="8">
                              <a
                                href="https://www.placetopay.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src="/img/place_to_pay/Logo_PlacetoPay.png" height="25" />
                              </a>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row
            v-else-if="form.model.voucher && ['Annuled'].includes(form.model.voucher.voucherStatus)"
            :gutter="20"
          >
            <el-col>
              <div class="card-container">
                <div class="card-column col-select current current-selected">
                  <div class="card">
                    <div class="content">
                      <div class="purchase">
                        <div class="purchase-image">
                          <img
                            :alt="$tc('annuled')"
                            class="image"
                            src="/img/transactions/suscription_bad.svg"
                            style="max-height: 250px"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="secured">
                        <small class="text-primary">
                          <h3>{{ $tc('voucherAlreadyAnnuled') }}</h3>
                        </small>
                        <div>
                          <small>{{ $tc('voucherAlreadyAnnuledMessage') }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="secured">
                        <small class="text-dark">
                          <h3>{{ $tc('paymentForPlan') }}</h3>
                        </small>
                        <div>
                          <h1>
                            {{
                              form.model.voucher.voucherAmountTotal | formatToCurrencyWithIsoCode
                            }}
                          </h1>
                          <small
                            >/{{
                              $tc(
                                $options.filters.toCamelCase(
                                  form.model.company.companyServiceFrequencyBilling
                                )
                              )
                            }}.</small
                          >
                        </div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="footer">
                        <span class="sub">
                          <small>
                            <em>
                              <address>
                                <a href="mailto:#">facturacion@aliaddo.com</a>
                              </address>
                              <address>
                                <el-button
                                  size="mini"
                                  type="text"
                                  @click="dialog.askedQuestionsElectronicPayments.visible = true"
                                  >{{ $tc('requentlyAskedQuestions') }}</el-button
                                >
                              </address>
                            </em>
                          </small>
                        </span>
                        <div class="" style="font-weight: bold">
                          <el-row :gutter="20">
                            <el-col :xs="24" :md="8">
                              <img
                                src="/img/place_to_pay/MASTERCARD.png"
                                alt="Mastercard"
                                height="20"
                              />
                              <img src="/img/place_to_pay/PSE.png" alt="Pse" height="20" />
                              <img src="/img/place_to_pay/VISA.png" alt="Visa" height="20" />
                              <img
                                src="/img/place_to_pay/AMEX.png"
                                alt="American Express"
                                height="20"
                              />
                            </el-col>
                            <el-col :xs="24" :md="8">
                              <a
                                href="https://aliaddo.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src="@/assets/img/svg/logo-aliaddo.svg" height="30" />
                              </a>
                            </el-col>
                            <el-col :xs="24" :md="8">
                              <a
                                href="https://www.placetopay.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src="/img/place_to_pay/Logo_PlacetoPay.png" height="25" />
                              </a>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row v-else-if="!model.placeToPayPending.id" :gutter="20">
            <el-col :xs="24" :sm="12">
              <div v-if="form.model.isFirstVoucher" class="card-container">
                <div class="card-column col-select current current-selected">
                  <div class="card">
                    <div class="step">
                      <div class="number">
                        <span>
                          <i class="fad fa-file-invoice"> </i>
                        </span>
                      </div>
                      <div class="title">
                        <span>{{ $tc('updateDataCompanyForBill') }}</span>
                      </div>
                    </div>
                    <div class="content">
                      <form-person-address
                        ref="formPersonAddress"
                        :inside-in-dialog="false"
                        :item="form.model.address"
                      ></form-person-address>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-container">
                <div class="card-column col-select current current-selected">
                  <div class="card">
                    <div class="step">
                      <div class="number">
                        <span>
                          <i class="fad fa-building"> </i>
                        </span>
                      </div>
                      <div class="title">
                        <span>{{ $tc('dataBuyer') }}</span>
                      </div>
                    </div>
                    <div class="content">
                      <el-form-item :label="$tc('paymentType')" prop="paymentType">
                        <select-payment-method
                          v-model="form.model.redirectRequest.paymentType"
                          @input="onChangePaymentType"
                        ></select-payment-method>
                      </el-form-item>
                      <el-form-item
                        v-if="form.model.redirectRequest.paymentType === 'CR'"
                        :label="$tc('selectYourCreditCard')"
                        prop="creditCard"
                      >
                        <select-payment-credit-card
                          v-model="form.model.redirectRequest.buyer.creditCard"
                        ></select-payment-credit-card>
                      </el-form-item>
                      <el-form-item :label="$tc('documentType')" prop="documentType">
                        <select-place-to-pay-documents-type
                          v-model="form.model.redirectRequest.buyer.documentType"
                          @input="onChangeDocumentType"
                        ></select-place-to-pay-documents-type>
                      </el-form-item>
                      <el-form-item :label="$tc('numberDocument')" prop="document">
                        <el-input v-model="form.model.redirectRequest.buyer.document"></el-input>
                      </el-form-item>
                      <el-form-item :label="$tc('name', 1)" prop="name">
                        <el-input v-model="form.model.redirectRequest.buyer.name"></el-input>
                      </el-form-item>
                      <el-form-item :label="$tc('surname')" prop="surname">
                        <el-input v-model="form.model.redirectRequest.buyer.surname"></el-input>
                      </el-form-item>
                      <el-form-item :label="$tc('email')" prop="email">
                        <el-input v-model="form.model.redirectRequest.buyer.email"></el-input>
                      </el-form-item>
                      <el-form-item :label="$tc('mobile')" prop="mobile">
                        <el-input v-model="form.model.redirectRequest.buyer.mobile"></el-input>
                      </el-form-item>
                    </div>
                    <div class="content" style="text-align: left">
                      <el-alert
                        :description="$tc('returnToStoreHelper')"
                        :closable="false"
                        :center="false"
                        show-icon
                        type="info"
                      >
                      </el-alert>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12">
              <div class="card-container">
                <div class="card-column col-select current current-selected">
                  <div class="card">
                    <div class="content">
                      <div class="secured">
                        <small class="dark">
                          <h3>{{ $tc('paymentForPlan') }}</h3>
                        </small>
                        <div>
                          <h1>
                            {{ placeToPayAmountTotal | formatToCurrencyWithIsoCode }}
                          </h1>
                          <small
                            >/{{
                              $tc(
                                $options.filters.toCamelCase(
                                  form.model.company.companyServiceFrequencyBilling
                                )
                              )
                            }}.</small
                          >
                        </div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="center">
                        <el-alert
                          :description="$tc('dataPayerChangeHelper')"
                          :closable="false"
                          :center="false"
                          show-icon
                          type="info"
                        >
                        </el-alert>
                        <div class="container-btn">
                          <div v-if="model.formPaymentErrors.length > 0" class="left">
                            <el-alert type="error" :title="$tc('validateForm')">
                              <ul>
                                <li v-for="(item, index) in model.formPaymentErrors" :key="index">
                                  <b>{{ item.field ? `${$tc(item.field)}: ` : '' }}</b
                                  >{{ item.message }}
                                </li>
                              </ul>
                            </el-alert>
                          </div>
                          <div v-if="model.paymentErrorMessage.length > 0" class="left">
                            <el-alert type="error" :title="$tc('paymentError')">
                              <span>{{ model.paymentErrorMessage }}</span>
                            </el-alert>
                          </div>
                          <br />
                          <el-button
                            size="mini"
                            :loading="form.loading"
                            class="btn btn-select"
                            @click="onPayment"
                          >
                            {{ $tc('pay') }}
                          </el-button>
                          <div>
                            <el-button size="mini" type="text" @click="dialog.taxes.visible = true">
                              {{ $tc('taxes', 3) }}
                            </el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="footer">
                        <span class="sub">
                          <small>
                            <em>
                              <address>
                                <a href="mailto:#">facturacion@aliaddo.com</a>
                              </address>
                              <address>
                                <el-button
                                  size="mini"
                                  type="text"
                                  @click="dialog.askedQuestionsElectronicPayments.visible = true"
                                  >{{ $tc('requentlyAskedQuestions') }}</el-button
                                >
                              </address>
                            </em>
                          </small>
                        </span>
                        <div class="" style="font-weight: bold">
                          <el-row :gutter="20">
                            <el-col :xs="24" :md="8">
                              <img
                                src="/img/place_to_pay/MASTERCARD.png"
                                alt="Mastercard"
                                height="20"
                              />
                              <img src="/img/place_to_pay/PSE.png" alt="Pse" height="20" />
                              <img src="/img/place_to_pay/VISA.png" alt="Visa" height="20" />
                              <img
                                src="/img/place_to_pay/AMEX.png"
                                alt="American Express"
                                height="20"
                              />
                            </el-col>
                            <el-col :xs="24" :md="8">
                              <a
                                href="https://aliaddo.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src="@/assets/img/svg/logo-aliaddo.svg" height="30" />
                              </a>
                            </el-col>
                            <el-col :xs="24" :md="8">
                              <a
                                href="https://www.placetopay.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src="/img/place_to_pay/Logo_PlacetoPay.png" height="25" />
                              </a>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row v-else :gutter="20">
            <el-col>
              <div class="card-container">
                <div class="card-column col-select current current-selected">
                  <div class="card">
                    <div class="content">
                      <div class="purchase">
                        <div class="purchase-image">
                          <img
                            :alt="$tc('pending')"
                            class="image"
                            src="/img/transactions/pending.svg"
                            style="max-height: 250px"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="secured">
                        <small class="text-primary">
                          <h1>{{ $tc('transactionInProcess') }}</h1>
                        </small>
                        <div>
                          <small>{{ $tc('transactionInProcessMessage') }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="secured">
                        <small class="text-dark">
                          <h3>{{ $tc('paymentForPlan') }}</h3>
                        </small>
                        <div>
                          <h1>
                            {{
                              model.placeToPayPending.placeToPayAmountTotal
                                | formatToCurrencyWithIsoCode
                            }}
                          </h1>
                          <small
                            >/{{
                              $tc(
                                $options.filters.toCamelCase(
                                  form.model.company.companyServiceFrequencyBilling
                                )
                              )
                            }}.</small
                          >
                        </div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="footer">
                        <span class="sub">
                          <small>
                            <em>
                              <address>
                                <a href="mailto:#">facturacion@aliaddo.com</a>
                              </address>
                              <address>
                                <el-button
                                  size="mini"
                                  type="text"
                                  @click="dialog.askedQuestionsElectronicPayments.visible = true"
                                  >{{ $tc('requentlyAskedQuestions') }}</el-button
                                >
                              </address>
                            </em>
                          </small>
                        </span>
                        <span
                          v-if="form.model.redirectRequest.paymentType === 'CR'"
                          class="sub"
                          style="font-weight: bold"
                        >
                          <small>
                            <em>
                              <small>
                                <i>{{ $tc('paymentSubscribeMessage') }}</i>
                              </small>
                            </em>
                          </small>
                        </span>
                        <div class="" style="font-weight: bold">
                          <el-row :gutter="20">
                            <el-col :xs="24" :md="8">
                              <img
                                src="/img/place_to_pay/MASTERCARD.png"
                                alt="Mastercard"
                                height="20"
                              />
                              <img src="/img/place_to_pay/PSE.png" alt="Pse" height="20" />
                              <img src="/img/place_to_pay/VISA.png" alt="Visa" height="20" />
                              <img
                                src="/img/place_to_pay/AMEX.png"
                                alt="American Express"
                                height="20"
                              />
                            </el-col>
                            <el-col :xs="24" :md="8">
                              <a
                                href="https://aliaddo.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src="@/assets/img/svg/logo-aliaddo.svg" height="30" />
                              </a>
                            </el-col>
                            <el-col :xs="24" :md="8">
                              <a
                                href="https://www.placetopay.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src="/img/place_to_pay/Logo_PlacetoPay.png" height="25" />
                              </a>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <el-dialog
      :append-to-body="true"
      :modal-append-to-body="true"
      :title="$tc('askedQuestionsElectronicPayments')"
      :visible.sync="dialog.askedQuestionsElectronicPayments.visible"
      width="80%"
    >
      <div class="pull-left">
        <p>
          1. ¿Qué es PlacetoPay? PlacetoPay es la plataforma de pagos electrónicos que usa ALIADDO
          SAS para procesar en línea las transacciones generadas en la tienda virtual con las formas
          de pago habilitadas para tal fin.
        </p>
        <br />
        <p>
          2. ¿Cómo puedo pagar? En la tienda virtual de ALIADDO SAS usted podrá realizar su pago con
          los medios habilitados para tal fin. Usted, de acuerdo a las opciones de pago escogidas
          por el comercio, podrá pagar a través de tarjetas de crédito Visa, MasterCard.
        </p>
        <br />
        <p>
          3. ¿Es seguro ingresar mis datos bancarios en este sitio web? Para proteger tus datos
          ALIADDO SAS delega en PlacetoPay la captura de la información sensible. Nuestra plataforma
          de pagos cumple con los más altos estándares exigidos por la norma internacional PCI DSS
          de seguridad en transacciones con tarjeta de crédito. Además tiene certificado de
          seguridad SSL expedido por GeoTrust una compañía Verisign, el cual garantiza
          comunicaciones seguras mediante la encriptación de todos los datos hacia y desde el sitio;
          de esta manera te podrás sentir seguro a la hora de ingresar la información de su tarjeta.
          <br />
          Durante el proceso de pago, en el navegador se muestra el nombre de la organización
          autenticada, la autoridad que lo certifica y la barra de dirección cambia a color verde.
          Estas características son visibles de inmediato y dan garantía y confianza para completar
          la transacción en PlacetoPay. <br />
          PlacetoPay también cuenta con el monitoreo constante de McAfee Secure y la firma de
          mensajes electrónicos con Certicámara.
        </p>
        <br />
        <p>
          4. ¿Puedo realizar el pago cualquier día y a cualquier hora? Sí, en ALIADDO SAS podrás
          realizar tus compras en línea los 7 días de la semana, las 24 horas del día a sólo un clic
          de distancia.
        </p>
        <br />
        <p>
          5. ¿Puedo cambiar la forma de pago? Si aún no has finalizado tu pago, podrás volver al
          paso inicial y elegir la forma de pago que prefieras. Una vez finalizada la compra no es
          posible cambiar la forma de pago.
        </p>
        <br />
        <p>
          6. ¿Pagar electrónicamente tiene algún valor para mí como comprador? No, los pagos
          electrónicos realizados a través de PlacetoPay no generan costos adicionales para el
          comprador.
        </p>
        <br />
        <p>
          7. ¿Qué debo hacer si mi transacción no concluyó? En primera instancia, revisar si llegó
          un email de confirmación de la transacción a la cuenta de correo electrónico inscrita en
          el momento de realizar el pago, en caso de no haberlo recibido, deberás contactar a
          Facturación Aliaddo para confirmar el estado de la transacción.
        </p>
        <br />
        <p>
          8. ¿Qué debo hacer si no recibí el comprobante de pago? Por cada transacción aprobada a
          través de PlacetoPay, recibirás un comprobante del pago con la referencia de compra en la
          dirección de correo electrónico que indicaste al momento de pagar.
        </p>
        <br />
        <p>
          Si no lo recibes, podrás contactar a Facturación Aliaddo o a la línea 4874666 o al correo
          electrónico facturacion@aliaddo.com, para solicitar el reenvío del comprobante a la misma
          dirección de correo electrónico registrada al momento de pagar.
        </p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          type="text"
          class="btn"
          @click="dialog.askedQuestionsElectronicPayments.visible = false"
        >
          {{ $tc('accept') }}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="$tc('taxes', 3)"
      :visible.sync="dialog.taxes.visible"
      width="70%"
      append-to-body
    >
      <el-row :gutter="20">
        <el-col :xs="24" :sm="14">
          <el-form ref="formTaxes" :model="form.model.placeToPay">
            <el-form-item :label="$tc('reteFuente')">
              <!-- <el-form-item label=""> -->
              <!-- <div style="text-align: justify; text-justify: inter-word">
                <em>{{ $tc('resolution0689') }}</em>
                <a
                  href="https://storage.googleapis.com/assets.aliaddo.com/legal/Resolucion-0689.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ $tc('resolution', 3) }}</a>
              </div> -->
              <el-input
                v-model="form.model.placeToPay.placeToPayReteFuentePercent"
                type="numeric "
                :placeholder="$tc('reteFuente')"
              >
                <template slot="append "> % </template>
              </el-input>
            </el-form-item>
            <el-form-item :label="$tc('reteIca')">
              <el-input
                v-model="form.model.placeToPay.placeToPayReteIcaPercent"
                type="numeric "
                :placeholder="$tc('reteIca')"
              >
                <template slot="append "> x1000 </template>
              </el-input>
            </el-form-item>
            <el-form-item v-if="+form.model.voucher.voucherIvaValue > 0" :label="$tc('reteIva')">
              <el-input
                v-model="form.model.placeToPay.placeToPayReteIvaPercent"
                type="numeric"
                :placeholder="$tc('reteIva')"
              >
                <template slot="append"> % </template>
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="10">
          <el-card shadow="always">
            <div slot="header" class="clearfix">
              <span
                >{{ $tc('total', 2) }}
                <!-- <small>/ {{ $tc(toCamelCase(form.model.companyServiceFrequencyBilling))}}</small> -->
              </span>
            </div>
            <table width="100%">
              <col style="width: 40%" />
              <col style="width: 60%" />
              <tfoot>
                <tr>
                  <td>{{ $tc('base', 1) }}</td>
                  <td align="right">
                    {{ form.model.voucher.voucherTaxBase | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('reteFuente', 1) }}</td>
                  <td align="right">
                    {{ placeToPayReteFuenteValue | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $tc('reteIca', 2) }}</td>
                  <td align="right">
                    {{ placeToPayReteIcaValue | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
                <tr v-if="+form.model.voucher.voucherIvaValue > 0">
                  <td>{{ $tc('reteIva') }}</td>
                  <td align="right">
                    {{ placeToPayReteIvaValue | formatToCurrencyWithIsoCode }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h3>{{ $tc('total') }}</h3>
                  </td>
                  <td align="right">
                    <h3>
                      {{ placeToPayAmountTotal | formatToCurrencyWithIsoCode }}
                    </h3>
                  </td>
                </tr>
              </tfoot>
            </table>
          </el-card>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="dialog.taxes.visible = false">Ok</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { sendMessage, validateForm } from '@/helpers'
import { fieldRequiredByBlur } from '@/helpers/validations'
import dialog from '@/views/common/dialog'
import form from '@/views/common/form'
import formPersonAddress from '@/views/signup/form-person-address'
import axios from 'axios'
import { mapGetters } from 'vuex'

import model from './model'
import rules from './rules'

/* Vendors
-------------------------------------------------- */
/* End of Vendors
-------------------------------------------------- */
/* Aliaddo
-------------------------------------------------- */
/* End of Aliaddo
-------------------------------------------------- */
export default {
  name: 'PlaceToPayPayment',
  components: {
    formPersonAddress
  },
  props: {
    isEmbedded: {
      required: true,
      default: true,
      type: Boolean
    },
    source: {
      required: true,
      default: 'Plans'
    },
    detailType: {
      required: false
    }
  },
  data() {
    return {
      form: form({ model, rules }),
      dialog: {
        askedQuestionsElectronicPayments: dialog(),
        taxes: dialog()
      },
      model: {
        formPaymentErrors: [],
        paymentErrorMessage: '',
        placeToPayPending: {}
      }
    }
  },
  computed: {
    ...mapGetters(['vCompany', 'vUser']),
    placeToPayReteFuenteValue: _this =>
      +(
        _this.form.model.voucher.voucherTaxBase *
        (_this.form.model.placeToPay.placeToPayReteFuentePercent / 100)
      ).toFixed(2),
    placeToPayReteIcaValue: _this =>
      +(
        _this.form.model.voucher.voucherTaxBase *
        (_this.form.model.placeToPay.placeToPayReteIcaPercent / 1000)
      ).toFixed(2),
    placeToPayReteIvaValue: _this => {
      if (+_this.form.model.voucher.voucherIvaValue > 0) {
        return +(
          _this.form.model.voucher.voucherIvaValue *
          (_this.form.model.placeToPay.placeToPayReteIvaPercent / 100)
        ).toFixed(2)
      }
    },
    placeToPayAmountTotal: _this =>
      +(
        _this.form.model.voucher.voucherAmountTotal -
        (_this.placeToPayReteFuenteValue || 0) -
        (_this.placeToPayReteIcaValue || 0) -
        (_this.placeToPayReteIvaValue || 0)
      ).toFixed(2)
  },
  methods: {
    onChangeDocumentType(value) {
      const _this = this
      _this.form.rules.document.splice(0, _this.form.rules.document.length)
      if (value === 'PPN' || value === 'TAX' || value === 'LIC') {
        _this.form.rules.document.push(fieldRequiredByBlur, {
          message: _this.$tc('fieldHasInvalidCharacter'),
          // eslint-disable-next-line no-useless-escape
          pattern: /^[a-zA-Z0-9_\-]{4,16}$/,
          required: true,
          trigger: 'blur'
        })
      } else if (value === 'CC') {
        _this.form.rules.document.push(fieldRequiredByBlur, {
          message: _this.$tc('fieldHasInvalidCharacter'),
          // eslint-disable-next-line no-useless-escape
          pattern: /^[1-9][0-9]{3,9}$/,
          required: true,
          trigger: 'blur'
        })
      } else if (value === 'CE') {
        _this.form.rules.document.push(fieldRequiredByBlur, {
          message: _this.$tc('fieldHasInvalidCharacter'),
          // eslint-disable-next-line no-useless-escape
          pattern: /^([a-zA-Z]{1,5})?[1-9][0-9]{3,7}$/,
          required: true,
          trigger: 'blur'
        })
      } else if (value === 'TI') {
        _this.form.rules.document.push(fieldRequiredByBlur, {
          message: _this.$tc('fieldHasInvalidCharacter'),
          // eslint-disable-next-line no-useless-escape
          pattern: /^[1-9][0-9]{4,11}$/,
          required: true,
          trigger: 'blur'
        })
      } else if (value === 'NIT' || value === 'RUT') {
        _this.form.rules.document.push(fieldRequiredByBlur, {
          message: _this.$tc('fieldHasInvalidCharacter'),
          // eslint-disable-next-line no-useless-escape
          pattern: /^[1-9]\d{6,9}\-?\d?$/,
          required: true,
          trigger: 'blur'
        })
      } else if (value === 'CI') {
        _this.form.rules.document.push(fieldRequiredByBlur, {
          message: _this.$tc('fieldHasInvalidCharacter'),
          // eslint-disable-next-line no-useless-escape
          pattern: /^(\d{9}\-?\d)$/,
          required: true,
          trigger: 'blur'
        })
      } else if (value === 'RUC') {
        _this.form.rules.document.push(fieldRequiredByBlur, {
          message: _this.$tc('fieldHasInvalidCharacter'),
          // eslint-disable-next-line no-useless-escape
          pattern: /^\d{13,14}$/,
          required: true,
          trigger: 'blur'
        })
      } else if (value === 'CIP') {
        _this.form.rules.document.push(fieldRequiredByBlur, {
          message: _this.$tc('fieldHasInvalidCharacter'),
          // eslint-disable-next-line no-useless-escape
          pattern: /^(PE|N|E|\d+)\-\d+\-\d+$/,
          required: true,
          trigger: 'blur'
        })
      } else if (value === 'CPF') {
        _this.form.rules.document.push(fieldRequiredByBlur, {
          message: _this.$tc('fieldHasInvalidCharacter'),
          // eslint-disable-next-line no-useless-escape
          pattern: /^\d{2,3}\.?\d{3}\.?\d{3}\-?\d{2}$/,
          required: true,
          trigger: 'blur'
        })
      } else {
        _this.form.rules.document.push(fieldRequiredByBlur)
      }
    },
    onChangePaymentType(value) {
      const _this = this
      _this.form.rules.creditCard.splice(0, _this.form.rules.creditCard.length)
      if (value === 'CR') {
        _this.form.rules.creditCard.push({
          required: true,
          message: this.$tc('emptyCreditCard'),
          trigger: 'change'
        })
      }
    },
    onPayment() {
      const _this = this

      _this.model.formPaymentErrors = []

      const promiseFormPayment = new Promise((resolve, reject) => {
        _this.$refs.formPayment.validate((valid, errors) => {
          for (const attribute in errors) {
            errors[attribute].forEach(element => {
              _this.model.formPaymentErrors.push({
                field: element.field,
                message: element.message
              })
            })
          }

          resolve(valid)
        })
      })

      const promiseFormPersonAddress = new Promise((resolve, reject) => {
        if (!_this.$refs.formPersonAddress) {
          resolve(true)
        } else {
          _this.$refs.formPersonAddress.$refs.form.validate((valid, errors) => {
            console.log('%c⧭', 'color: #f279ca', valid, errors)
            for (const attribute in errors) {
              errors[attribute].forEach(element => {
                _this.model.formPaymentErrors.push({
                  message: element.message
                })
              })
            }

            resolve(valid)
          })
        }
      })

      Promise.all([promiseFormPayment, promiseFormPersonAddress])
        .then(isValid => {
          if (isValid.includes(false)) {
            validateForm()

            return
          }

          _this.fillPlaceToPay()

          let uri = 'payments/placeToPay'

          if (_this.source === 'Plans') {
            uri = `${uri}/${_this.detailType}`
          }
          if (_this.form.model.redirectRequest.paymentMethod === '_PSE_') {
            uri = `${uri}/redirection/request`
          } else {
            uri = `${uri}/subscribe`
          }

          _this.form.loading = true
          if (_this.form.model.company.companyServiceSource === 'package') {
            _this.form.model.company.companyServiceSource = 'site'
          }
          console.log('%c⧭', 'color: #7f7700', _this.form.model)
          _this.$http
            .post(uri, _this.form.model)
            .then(response => {
              _this.messagePayment(response.body)
              setTimeout(() => (_this.form.loading = false), 5000)
            })
            .catch(ex => {
              if (_this.isEmbedded) {
                _this.model.paymentErrorMessage = ex.body
                sendMessage({ type: 'placeToPayException', message: ex.body })
              }
              _this.form.loading = false
            })
        })
        .catch(error => {
          console.error('%c⧭', 'color: #99adcc', error)
        })
    },
    getForCompanyForPayment() {
      const _this = this
      _this.form.loading = true
      _this.$http
        .get(
          `companies/${_this.$route.params.companyId}/forPayment/${_this.form.model.company.serviceId}`
        )
        .then(result => {
          if (result.body) {
            _this.form.model.redirectRequest.buyer = {
              address: result.body.company.companyAddress,
              name: result.body.company.companyName,
              company: result.body.company.companyName,
              document: result.body.company.companyIdentification,
              documentType: 'NIT',
              email: result.body.company.companyEmail,
              mobile: result.body.company.companyPhone,
              surname: result.body.company.companyName
            }
            _this.form.model.companyId = result.body.company.id
            _this.form.model.isFirstVoucher = result.body.isFirstVoucher
            if (result.body.person && result.body.person.address.length > 0) {
              _this.form.model.address = result.body.person.address[0]
            }
          }
        })
        .finally(() => {
          _this.form.loading = false
        })
    },
    getPending() {
      const _this = this
      if (_this.form.model.voucher.id) {
        _this.form.loading = true
        _this.$http
          .get(`payments/placeToPay/${_this.form.model.voucher.id}/pending`)
          .then(response => (_this.model.placeToPayPending = response.body))
          .finally(_ =>
            setTimeout(() => {
              _this.form.loading = false
            }, 100)
          )
      } else if (_this.form.model.company.id) {
        _this.form.loading = true
        _this.$http
          .get(`payments/placeToPay/${_this.form.model.company.id}/pending/company`)
          .then(response => (_this.model.placeToPayPending = response.body))
          .finally(_ =>
            setTimeout(() => {
              _this.form.loading = false
            }, 100)
          )
      }
    },
    getIp() {
      axios
        .get('https://api.ipify.org?format=json')
        .then(response => (this.form.model.redirectRequest.ipAddress = response.data.ip))
    },
    fillForm(model) {
      const _this = this
      _this.form.model = { ...model }
      _this.form.model.placeToPay = {
        ..._this.form.model.placeToPay,
        ...{
          placeToPayReteFuentePercent: '',
          placeToPayReteIcaPercent: '',
          placeToPayReteIvaPercent: ''
        }
      }
      _this.getPending()
      _this.getForCompanyForPayment()
      _this.getIp()
      _this.form.model.redirectRequest.userAgent = navigator.userAgent
    },
    fillPlaceToPay() {
      const _this = this
      _this.form.model = {
        ..._this.form.model,
        placeToPay: {
          ..._this.form.model.placeToPay,
          placeToPaySource: _this.source,
          placeToPayReteFuenteValue: _this.placeToPayReteFuenteValue,
          placeToPayReteIcaValue: _this.placeToPayReteIcaValue,
          placeToPayReteIvaValue: _this.placeToPayReteIvaValue,
          placeToPayIvaBase: _this.form.model.voucher.voucherIvaBase,
          placeToPayIvaPercent: _this.form.model.voucher.voucherIvaPercent,
          placeToPayIvaValue: _this.form.model.voucher.voucherIvaValue,
          placeToPayAmount: _this.form.model.voucher.voucherAmount,
          placeToPayAmountTotal: _this.placeToPayAmountTotal
        }
      }
      _this.form.model.voucher.serviceId =
        _this.form.model.plan.serviceId || _this.form.model.voucher.serviceId
      _this.form.model.company.companyId = _this.$route.params.companyId
      if (_this.form.model.redirectRequest.paymentType === 'CR') {
        _this.form.model.redirectRequest.paymentMethod =
          _this.form.model.redirectRequest.buyer.creditCard
      } else {
        _this.form.model.redirectRequest.paymentMethod =
          _this.form.model.redirectRequest.paymentType
      }
    },
    messagePayment(response) {
      const _this = this
      if (response.processUrl && _this.isEmbedded) {
        sendMessage({ type: 'placeToPayProcessUrl', url: response.processUrl })
      } else if (response.status.status === 'ERROR' || response.status.status === 'FAILED') {
        _this.$message({
          message: response.status.message,
          type: 'error'
        })
        sendMessage({
          type: 'placeToPayStatus',
          message: response.status.message
        })
      } else if (response.processUrl && !_this.isEmbedded) {
        window.location.replace(response.processUrl)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/transactions/personalize.scss';
</style>
